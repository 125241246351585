export const environment = {
    production: true,
    portalLive: true,
    version: '1.0.0',
    config: {
        romanianLanguageDataTable: {
            sEmptyTable: 'Niciun rezultat găsit',
            sInfo: 'Afișate de la _START_ până la _END_ din _TOTAL_ înregistrări',
            sInfoEmpty: 'Afișate de la 0 până la 0 din 0 înregistrări',
            sInfoFiltered: '(filtrat din _MAX_ înregistrări)',
            sInfoPostFix: '',
            sInfoThousands: ',',
            sLengthMenu: 'Afișează _MENU_ înregistrări pe pagină',
            sLoadingRecords: 'Încărcare...',
            sProcessing: 'Procesare...',
            sSearch: 'Caută:',
            sZeroRecords: 'Niciun rezultat găsit',
            oPaginate: {
                sFirst: 'Prima',
                sLast: 'Ultima',
                sNext: 'Următoarea',
                sPrevious: 'Anterioară',
            },
        },
        fromTokenValid: 'auth-core.ps4.ro',
        tokenKey: 'wpl-ps4-cetatean-app-token', // this token is used for every request where is needed
        userKey: 'wpl-ps4-cetatean-app-user', // here we find local storage with minimal user data
        storageKey: 'wpl-ps4-cetatean', // here we find key for predefined local storage for app functionalities
        copyrightText: '© Copyright 2024 - Primăria Sectorului 4',
        appName: 'Primăria Sectorului 4',
        platformName: 'Platforma Online',
        defaultLNG: 'ro',
        emailRegex:
            '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$',
        codeSMSRegex: /^[0-9]+$/,
        phoneRegex: '[0-9]{6,20}',
        cnpRegex: '[0-9]{13,13}',
        validatorsAccrossApp: {
            minPassword: 8,
            maxPassword: 50,
            emailMaxLength: 50,
            minStringLength: 2,
            maxStringLength: 60,
            minSmsCodeLength: 1,
            maxSmsCodeLength: 1,
        },
        customNotifications: {
            icons: {
                success: 'success',
                error: 'error',
                info: 'info',
                warning: 'warning',
            },
            headers: {
                success: 'Felicitări!',
                error: 'Atenție',
                successForm: 'Felicitări!',
            },
            generalMessages: {
                error:
                    'Ne pare rău, dar am întâmpinat o problemă. Vă rugăm să reîncercați. Dacă problema persistă, vă rugăm să ne contactați prin butonul de suport IT.',
                fileErrorMsg:
                    'Ne pare rău, dar fișierul selectat nu a putut fi încărcat. Vă rugăm să reîncercați!',
            },
        },
        loginUrl: '/pages/login',
        continueAppointmentDelayInMinutes: 15,
        continueAppointmentMaxLimit: 4
    },
    interop: {
        programariCore: {
            basePath: 'https://core.deps4.ro/',
            api: {
                cetatean: {
                    uploadFile: 'cetatean/cerere/upload-file',
                    downloadDocumentSolicitare: 'cetatean/cerere/document/',
                    saveMessageSolicitare: 'cetatean/cerere/solicitare-save-message/',
                    viewSolicitare: 'cetatean/cerere/solicitare-view/',
                },
                public: {
                }
            }
        },
        authCore: {
            basePath: 'https://auth-core.ps4.ro/',
            user: {
                refreshLogin: 'cetatean/refresh',
                loginUrl: 'cetatean/login',
                resendCode: 'cetatean/cetatean-resend-code',
                confirmCode: 'cetatean/cetatean-confirm-code',
                checkEmailPasswordReset: 'cetatean/reset-password-cnp',
                resendCodePasswordReset: 'cetatean/reset-password-resend-code',
                verifyCodePasswordReset: 'cetatean/reset-verify-code',
                updatePasswordReset: 'cetatean/reset-password-update',
                fileUploadRegister: 'cetatean/upload-file-register',
                registerAccount: 'cetatean/cetatean-register',
                registerCheckCNP: 'cetatean/cetatean-register-check-cnp',
                checkPinRegister: 'cetatean/cetatean-check-pin-register',
                registerAccountInrolat: 'cetatean/cetatean-register',
                resetPINCodeInrolatAccount: 'cetatean/cetatean-reset-pin-code-inrolare',
                findDetails: 'cetatean/user',
                logoutUrl: 'cetatean/logout',
                updatePasswordAccount: 'cetatean/password',
                updateInfoAccount: 'cetatean/profile-update',
                addressSave: 'cetatean/address/save',
                addressUpdate: 'cetatean/address/update',
                addressList: 'cetatean/address/list',
                addressDelete: 'cetatean/address/',
                sendSolicitare: 'cetatean/suport-online',
                sendSolicitareInsideAcc: 'cetatean/authenticated-suport-online',
            },
            ocr: {
                processCi: 'api/process-ci',
                processCert: 'api/process-cert',
            },
            announces: 'cetatean/platform/announces/list/', // + {slug}
            article: {
                list: 'cetatean/article/list',
                find: 'cetatean/article/find/' // + {id}
            },
            contact: 'contact',
            solicitare: {
                storeURL: 'cetatean/solicitare/store',
                updateURL: 'cetatean/solicitare/store',
                listDT: 'cetatean/solicitare/list-dt',
                viewSolicitare: 'cetatean/cerere/solicitare-view/',
                downloadDocumentSolicitare: 'cetatean/cerere/document/',
                saveMessageSolicitare: 'cetatean/cerere/solicitare-save-message/',
                findDraft: 'cetatean/solicitare/findDraft/'
            },
            beneficiar: {
                listUrl: 'cetatean/beneficiar/list',
                listValidatedUrl: 'cetatean/beneficiar/list-validated',
                saveUrl: 'cetatean/beneficiar/save',
                updateUrl: 'cetatean/beneficiar/update',
                removeUrl: 'cetatean/beneficiar/remove',
                findUrl: 'cetatean/beneficiar/find/',
            },
            judete: {
                getJudete: 'cetatean/judete/list',
            },
            documenteleMele: {
                getDocumenteleMeleDT: 'cetatean/citizen-documents/list-dt',
                fileDownload: 'cetatean/citizen-documents/download/', // + id_document/id_fisier
            }
        },
        urbanismCore: {
            basePath: 'https://urb-core.ps4.ro/',
            api: {
                cerere: {
                    store: 'cetatean/cerere/store',
                    printCerere: 'cetatean/cerere-precompletare/print/', // + {slug}
                    fileUpload: 'cetatean/cerere/upload-file',
                    saveMessageSolicitare: 'cetatean/cerere/solicitare-save-message/',
                    viewSolicitare: 'cetatean/cerere/solicitare-view/',
                    downloadDocumentSolicitare: 'cetatean/cerere/document/',
                    storeDraft: 'cetatean/solicitare/storeDraft'
                },
            },
            platform: {
                faq: 'cetatean/platform/faq/list/', // + {slug}
                content: 'cetatean/platform/content/list/', // + {slug}
                announces: 'cetatean/platform/announces/list/', // + {slug} // old, deprecated
            },
            registre: {
                getRegistreDT: 'registru/list-dt',
                getRegistre: 'document-oficial-registru/list',
            }
        },
        paymentsCore: {
            basePath: 'https://pay-core.taxeimpozite4.ro/',
            api: {
                payments: {
                    requestPayment: 'cetatean/mobile-payment-request-v2',
                    getTax: 'cetatean/get-taxes-cetatean-v2',
                    processPaymentsRecurente: 'api/process-payments-recurente/',
                    getCards: 'cetatean/get-cards',
                    removeCard: 'cetatean/remove-card/',
                    getTipuriAmenzi: 'cetatean/payments/tipuri-amenzi-list',
                    checkSoldAmendaToPay: 'cetatean/payments/tipuri-amenzi-interogare',
                    processPaymentRecurentaAmenda:
                        'cetatean/payments/payment-recurenta-amenda/',
                    requestPaymentAmenda: 'cetatean/payments/request-payment-amenda',
                    getTipuriTaxeNedebitate: 'cetatean/payments/tipuri-taxe-nedebitate-list',
                    checkSoldTaxeNedebitateToPay:
                        'cetatean/payments/tipuri-taxe-nedebitate-interogare',
                    requestPaymentTaxeNedebitate:
                        'cetatean/payments/request-payment-taxe-nedebitate',
                    processPaymentRecurentaTaxeNedebitate:
                        'cetatean/payments/payment-recurenta-taxe-nedebitate/',
                    exportPayment: 'cetatean/payments/export-payment-details/',
                },
                announcements: {
                    getAnnouncements: 'api/announcements/',
                },
                public: {
                    checkCUI: 'validate-cui',
                }
            },
        },
        dgitlCore: {
            basePath: 'https://core.taxeimpozite4.ro/',
            api: {
                cetatean: {
                    storeForm: 'cetatean/dgitl/storeForm',
                    listSolicitari: 'cetatean/dgitl/solicitari-list',
                    initializeForm: 'cetatean/dgitl/getFormInit/',
                    viewSolicitare: 'cetatean/dgitl/solicitare-view/',
                    saveMessageSolicitare: 'cetatean/dgitl/solicitare-save-message/',
                    saveSolicitareCertificatFiscal:
                        'cetatean/dgitl/process-certificat-fiscal-request',
                    cfGetMatricole: 'cetatean/dgitl/cf-get-matricole',

                    uploadFileRegister: 'cetatean/upload-file-register',
                    uploadFileRegisterBase64: 'cetatean/upload-file-register-base64',
                    downloadDocumentSolicitare: 'cetatean/dgitl/document/',

                    announcements: {
                        getAnnouncements: 'cetatean/dgitl/announcements/',
                    },
                },
                operator: {
                    solicitari: 'api/dgitl/storeForm',
                },
                public: {
                    initializeForm: 'dgitl/getFormInit/',
                    storeForm: 'dgitl/storeForm',
                    uploadFileRegister: 'upload-file-register',
                    downloadFile: 'download-file/'
                },
            },
        },
        autcomCore: {
            basePath: 'https://autcom-core.ps4.ro/',
            api: {
                cetatean: {
                    store: 'cetatean/cerere/store',
                    uploadFile: 'cetatean/cerere/upload-file',
                    downloadDocumentSolicitare: 'cetatean/cerere/document/',
                    saveMessageSolicitare: 'cetatean/cerere/solicitare-save-message/',
                    viewSolicitare: 'cetatean/cerere/find/',
                },
                public: {
                    store: 'cerere/store',
                    uploadFile: 'cerere/upload-file',
                    getStreet: 'cetatean/strazi/get_street/',
                }
            }
        },
    },

    // config: {
    // 	fromTokenValid: 'https://development.auth-core.webproductionlabs.com/login',
    // 	tokenKey: 'dgitl-web-app-token',
    // 	userKey: 'dgitl-web-app-user',
    // 	storageKey: "dtigl-web-app",
    // 	copyrightText: "Copyright © DGITL Sector 4 2021",
    // 	defaultLNG: 'ro',
    // 	emailRegex : "^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$",
    // 	codeSMSRegex : /^[0-9]+$/,
    // 	phoneRegex: '[0-9]{6,20}',
    // 	cnpRegex: '[0-9]{13,13}',
    // 	validatorsAccrossApp: {
    // 		minPassword: 8,
    // 		maxPassword: 30,
    // 		emailMaxLength: 50,
    // 		minStringLength: 2,
    // 		maxStringLength: 60,
    // 		minSmsCodeLength: 1,
    // 		maxSmsCodeLength: 1
    // 	},
    // 	customNotifications: {
    // 		icons: {
    // 			success: "success",
    // 			error: "error",
    // 			info: "info",
    // 			warning: "warning"
    // 		},
    // 		headers: {
    // 			success: "Success",
    // 			error: "Eroare",
    // 			successForm: "Felicitari!"
    // 		},
    // 		generalMessages: {
    // 			error: "Ne pare rau, dar am intampinat o problema. Va rugam sa reincercati. Daca problema persista, va rugam sa ne contactati prin butonul de suport IT."
    // 		}
    // 	}
    // },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
