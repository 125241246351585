<div class="notification">
    <div class="notification-welcome">
        <img src="assets/images/Group 44.svg" alt="welcome" />
        <mat-card class="notification-welcome__card">
            <mat-card-header class="notification-welcome__header">
                Notificările dumneavoastră
            </mat-card-header>
            <mat-card-content class="notification-welcome__content">
                În cadrul acestei sectiuni puteți să vă gestionați notificările din
                cadrul Platformei Online a Primăriei Sectorului 4.
            </mat-card-content>
        </mat-card>
    </div>

    <div class="notification-items" *ngFor="let info of infos">
        <div class="notification-item">
            <mat-icon>info</mat-icon>
            <div class="notification-item-message">
                {{ info.message }}
                <a class="notification-item-link" href="https://s4programare.netlify.app/programare" target="_blank">
                    {{ info.link }}
                </a>
            </div>
        </div>
    </div>
</div>