<div class="navbar">
  <div class="mobile-version__drawer-container">
    <button mat-button class="menu-button" (click)="sidenav.toggle()">
      <mat-icon>{{ isMenuOpen ? "menu_open" : "menu" }}</mat-icon>
    </button>
    <mat-sidenav-container class="sidenav-container">
      <mat-sidenav #sidenav autoFocus="false" [(opened)]="isMenuOpen" mode="over" class="sidenav"
        (click)="onSidenavClick()">
        <div class="drawer__buttons">
            <mat-accordion class="sidenav-accordion">
                <p class="sidenav-link" routerLink="/dashboard/home" value="home" *ngIf="!isProduction()">
                    <img src="assets/icons/home.svg" alt="Acasă" />
                    <span>Acasă</span>
                </p>
                <p class="sidenav-link" routerLink="/dashboard/solicitari/institutie" value="requests"
                    *ngIf="!isProduction()">
                    <img src="assets/icons/docs.svg" alt="Adaugă solicitare" />
                    <span>Adaugă solicitare</span>
                </p>
                <p class="sidenav-link" routerLink="/dashboard/solicitari/institutie/urbanism" value="requests"
                    *ngIf="isProduction()">
                    <img src="assets/icons/docs.svg" alt="Adaugă solicitare" />
                    <span>Adaugă solicitare</span>
                </p>
                <p class="sidenav-link" routerLink="/dashboard/solicitari" value="requests">
                    <img src="assets/icons/docs.svg" alt="Solicitările mele" />
                    <span>Solicitările mele</span>
                </p>
                <p class="sidenav-link" routerLink="/dashboard/documentele-mele" value="myDocuments" *ngIf="!isProduction()">
                    <img src="assets/icons/documentele-mele.svg" alt="Documentele mele" />
                    <span>Documentele mele</span>
                </p>
                <p class="sidenav-link" routerLink="/dashboard/payments" value="payments" *ngIf="!isProduction()">
                    <img src="assets/icons/wallet.svg" alt="Plăți Online" />
                    <span>Plăți Online</span>
                </p>
                <!-- <p class="sidenav-link" routerLink="/dashboard/utils" value="utils" *ngIf="!isProduction()">
                <img src="assets/icons/icon-addcard.svg" alt="Utile" />
                <span>Utile</span>
                </p> -->
                <p class="sidenav-link" routerLink="/dashboard/settings" value="settings">
                    <img src="assets/icons/gear.svg" alt="Setări" />
                    <span>Setări</span>
                </p>
                <div class="footer">
                    <p class="sidenav-link" (click)="logout()">
                        <img src="assets/icons/logout.svg" alt="Deconectare" />
                        <span class="logout-button--text">Deconectare</span>
                    </p>
                </div>
            
            </mat-accordion>
        </div>
      </mat-sidenav></mat-sidenav-container>
  </div>
  <mat-spinner *ngIf="isLoading" diameter="25"></mat-spinner>
  <div class="username__container">
        <div class="username__icon">
          <div class="username__icon--letters">{{ usernameLetters }}</div>
        </div>
        <div class="username">{{ username }}</div>
  </div>
  <!-- <div class="username__notifications move-up" [ngClass]="{ 'move-up': !userNotifications }"
    routerLink="/dashboard/notifications" *ngIf="showBell">
    <img src="assets/icons/icon-bell.svg" alt="Notificari" />
    <div class="username__notifications--chip" [ngClass]="{ 'hide-chip': !userNotifications }">
      {{ userNotifications }}
    </div>
  </div> -->
</div>
