<div class="welcome-card">
	<div class="welcome-card_header">
		<img src="assets/images/Group 9.svg" alt="" />
		<div class="welcome-card_header-headline">
			<h2>Solicitările dumneavoastră</h2>
			<h4>Registratura Online a Primăriei Sectorului 4</h4>
			<h4 class="d-flex align-items-center gap-2 ">
				<img class="icon" src="assets/icons/alert.svg" alt="">
				<strong>Istoricul dumneavoastră din platforma svu.ps4.ro(DGUAT) va apărea în curând</strong>
			  </h4>
		</div>
	</div>
	<button class="initialize-request" (click)="initializeRequest()">
		<img class="btn-img" src="assets/icons/add.svg" alt="Solicitarile dumneavoastră" /> INIȚIAȚI O SOLICITARE
	</button>
</div>

<div class="announcements-card">
	<app-announcements [slug]="pageSlug"></app-announcements>
</div>

<!-- <div class="table-filter">
  <mat-form-field appearance="outline">
    <mat-label>Filtrare Solicitare</mat-label>
    <mat-select (selectionChange)="onChange($event)">
      <mat-option [value]="RequestStatus.new">{{ RequestStatus.new }}
      </mat-option>
      <mat-option [value]="RequestStatus.work">{{ RequestStatus.work }}
      </mat-option>
      <mat-option [value]="RequestStatus.closed">{{ RequestStatus.closed }}
      </mat-option>
      <mat-option [value]="RequestStatus.canceled">{{ RequestStatus.canceled }}
      </mat-option>
      <mat-option (click)="resetFilter()">Sterge filtru</mat-option>
    </mat-select>
  </mat-form-field>
</div> -->

<div class="section position-relative">
	<div class="section-top">
		<h3 class="section-title">Solicitări</h3>
		<div class="section-top-actions">
			<button type="button" class="btn btn-sm  pull-right ms-1" (click)="rerenderDT(false)">
				<img src="assets/icons/icon-history.svg" alt="">
			</button>
		</div>
	</div>

	<div class="card-container d-flex p-2" *ngIf="showCard">
		<div class="row w-100 m-0 p-3">
			<div class="col">
				<mat-form-field>
					<mat-label>Status</mat-label>
					<mat-select id="status" [(ngModel)]="statusValue" (change)="rerenderDT()">
						<mat-option *ngFor="let status of status" [value]="status.value" (change)="rerenderDT()">
							{{ status.key }}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field>
					<mat-label>Draft</mat-label>
					<mat-select id="draft" [(ngModel)]="draftValue" (change)="rerenderDT()">
						<mat-option *ngFor="let draftItem of draft" [value]="draftItem.value" (change)="rerenderDT()">
							{{ draftItem.key }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="col">
				<mat-form-field>
					<mat-label>Instituție</mat-label>
					<mat-select id="institutie" [(ngModel)]="institutieValue" (change)="rerenderDT()">
						<mat-option *ngFor="let inst of institutie" [value]="inst.value" (change)="rerenderDT()">
							{{ inst.key }}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field>
					<mat-label>Introduceți numărul solicitării</mat-label>
					<input matInput [(ngModel)]="solicitareValue" type="number" />
				</mat-form-field>
			</div>
		</div>
		<div class="row w-100">
			<div class="col d-flex justify-content-end">
				<button (click)="resetForm()" type="button" class="btn btn-sm btn-secondary">
					Resetează
				</button>
				<button (click)="applyFilters()" type="button" class="btn btn-sm btn-primary ms-2">
					Aplică
				</button>
			</div>
		</div>
	</div>

	<div class="row  d-flex p-2" style="margin-right: 0; margin-left: 0;">
		<div class="col" *ngIf="!isProduction()">
			<button class="open-filter-btn" (click)="toggleCard()" [class.active]="showCard">
				<img src="assets/images/filter-icon.png" alt="Filtrare" />
			</button>
		</div>

		<div class="table-container" style="flex-shrink: none;">
			<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover  w-100"></table>
		</div>
		<div class="section-spinner position-absolute" *ngIf="isLoading">
			<div class="spinner position-absolute top-50 start-50 translate-middle">
				<mat-spinner></mat-spinner>
				<h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
			</div>
		</div>
	</div>