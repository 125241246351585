<div class="pages">
    <div class="pages-container">
        <!-- Welcome -->
        <div class="pages-welcome">
            <div class="pages-welcome-container">
                <span class="d-flex flex-row gap-4 justify-content-start">
                    <img src="assets/images/stema.svg" alt="PS4"  />
                    <img src="assets/images/sect4-logo2.svg" alt="PS4" />
                </span>
                <div class="pages-welcome-title">Bine ați venit!</div>
                <div class="pages-welcome-description">
                    Autentificați-vă în cadrul platformei online<br />
                    <span class="pages-welcome-bold">
                        Primăria Sectorului 4 </span>
                </div>
            </div>
        </div>
        <!-- Content -->
        <div class="pages-content">
            <router-outlet></router-outlet>
        </div>
    </div>
    <!-- <div class="pages-footer">Copyright © DGITL Sector 4 - 2021 - 2023</div> -->
</div>